.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body, html {
  overflow-x: hidden;
  width: 100%;
  background-color: #0D0E10;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

p {
  color: #9CA3AF;
}

h5 {
  color: #E5E7EB;
}

h2 {
  color: #E5E7EB;
}

h4 {
  color: #E5E7EB;
}

.load_section {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontally */
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loading_p {
  height: 30px;
  margin-right: 5px;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.loading-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-dots span {
  display: block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #333;
  border-radius: 50%;
  opacity: 0.2;
  animation: loadingDots 1.4s infinite ease-in-out both;
}

.loading-dots span:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dots span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes loadingDots {
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0.2;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}

.resize-image {
  width: 100px; /* Sets the image width to 100 pixels */
  height: auto; /* Adjusts the height automatically to maintain aspect ratio */
}

.sxd {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center; /* Horizontally */
  align-items: flex-end;
}

.custom-list-item {
 
  padding-left: 0.25rem; /* Adjust this value to control the space */
}